import React, {Component} from 'react';
import Waypoint from 'react-waypoint';

class WaypointView extends Component {

    constructor(props) {
        
        super(props);
        
        
        var newText = '';

        this.props.children.forEach(function(child){

            //console.log(child.type,child.props.className,child.props.children)

            var str = ''+child.props.children;
            
            for(var i=0;i<str.length;i++){
                let s = str[i];
                if(child.props.className === 'acc') {
                    newText+='<span class="acc">'+s+'</span>';

                }else {
                    newText+='<span>'+s+'</span>';
                }
            }


        })

        
        this.state = {
            isInView : (this.props.always)?'never':'',
            newText : newText
        }
        
        
        
        
        this.wpOnEnter = this.wpOnEnter.bind(this);
        this.wpOnLeave = this.wpOnLeave.bind(this);
        
    }//eof constructor
    wpPositionChange(target) {

        //console.log('position changed',target)
  
    };
    wpOnLeave(target) {
  
      //console.log("onleave",target)
      this.setState({
        isInView : '' //
      })
    };
    wpOnEnter(target) {
  
        console.log("onenter",target)
        this.setState({
          isInView : this.props.always
        })
    };

    render() {
        return (

            <Waypoint onEnter = {this.wpOnEnter} onLeave = {this.wpOnLeave}><h2 className={this.state.isInView} dangerouslySetInnerHTML={{ __html: this.state.newText }}></h2></Waypoint>

        );
    }



}

export default WaypointView;